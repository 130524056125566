<template>
<div class="app-wrapper">

    <div class="app-content pt-3 p-md-3 p-lg-4">

        <div class="modal fade" id="staticLog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Log Pembayaran</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- {{edit}} -->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tanggal</th>
                                    <th scope="col">Bayar</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(post, index) in edit2" :key="index">
                                    <th scope="row">{{index+1}}</th>
                                    <td>{{ filterdate(post.created_at) }}</td>
                                    <td>{{"Rp " + post.bayar.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</td>
                                    <td>
                                        <!-- <button class="btn btn-primary text-center">Hapus</button> -->
                                        <button class="btn btn-primary text-white" @click="prints(invId, index)">Print</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Barang</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- {{edit}} -->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Barang</th>
                                    <th scope="col">Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(post, index) in edit" :key="index">
                                    <th scope="row">{{index+1}}</th>
                                    <td>{{ post.barang.nama_barang }}</td>
                                    <td>{{ post.qty }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>



        <div class="container-fluid">
            <div class="container-fluid mt-5">
                <div class="row">
                    <div :class="cart.length>0? 'col-md-6':'col-md-12'">
                        <div class="app-card app-card-orders-table shadow-sm mb-5">
                            <div class="app-card-body">
                                <div class="row g-3 mb-4 ms-2 me-2 align-items-center justify-content-between">
                                    <div class="col-12">
                                        <h1 class="app-page-title mb-0">Tambah Barang Keluar</h1>
                                    </div>
                                    <div class="col-auto">
                                        <div class="docs-search-form row gx-1 align-items-center">

                                            <div class="col-8 col-md-auto mt-2 mt-md-0">

                                                <div class="input-group ">
                                                    <input v-on:keyup.enter="getSearchbarang" v-model="searchbarang" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Search">
                                                    <button @click="getResetbarang" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> X </button>
                                                </div>
                                            </div>
                                            <div class="col-4 col-md-auto mt-2 mt-md-0">
                                                <button @click="getSearchbarang" type="submit" class="btn app-btn-secondary w-100" style="height:40px">Search</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="table-responsive m-2">
                                        <table class="table app-table-hover mb-0 text-center">
                                            <thead>
                                                <tr>
                                                    <th class="cell">No</th>
                                                    <th class="cell">Nama Barang</th>
                                                    <th class="cell">Kantor Cabang</th>
                                                    <th class="cell">Kode Barcode</th>
                                                    <th class="cell">Merek</th>
                                                    <th class="cell">Harga</th>
                                                    <th class="cell">Stok</th>
                                                    <th class="cell">Aksi</th>
                                                </tr>
                                            </thead>

                                            <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                                <tr v-for="(postbarang, index) in postbarangs" :key="index">
                                                    <td class="cell">{{ index +1 }}</td>
                                                    <td class="cell">{{ postbarang.nama_barang }}</td>
                                                    <td class="cell">{{ postbarang.kantor_cabang.nama_cabang }}</td>
                                                    <td class="cell">
                                                      {{  postbarang.kode_barcode }}
                                                    </td>
                                                    <td class="cell">{{ postbarang.merek}}</td>
                                                    <td class="cell">
                                                        <s style="color:red" v-if="postbarang.diskon!=0">{{ "Rp" + postbarang.harga_cash.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</s>
                                                        {{ "Rp" + (postbarang.harga_cash-(postbarang.harga_cash*postbarang.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}
                                                        <br>
                                                        <s style="color:red" v-if="postbarang.diskon!=0">{{ "Rp" + postbarang.harga_hutang.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</s>
                                                        {{ "Rp" + (postbarang.harga_hutang-(postbarang.harga_hutang*postbarang.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}
                                                    </td>
                                                    <td class="cell">{{ postbarang.stok }}</td>
                                                    <td class="text-center cell">
                                                        <button class="btn btn-primary text-white" @click="tambahkeranjang(postbarang.id)">Tambah</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody style="border-top: 1px solid grey" v-else>
                                                <tr>
                                                    <td colspan="9" class="text-center " style="height: 200px; padding-top: 100px;">
                                                        <h3> Loading....</h3>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- <Pagination :data="laravelData" @pagination-change-page="getResults" /> -->

                                        <nav class="app-pagination mt-3">
                                            <ul class="pagination justify-content-center">

                                                <li :class="[paginationbarang.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarangs(paginationbarang.prev_page_url)">Previous</a></li>
                                                <li v-if="paginationbarang.current_page !=1 && paginationbarang.current_page !=2" :class="[paginationbarang.current_page == 1 ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarang(1)" style=" cursor: pointer;">1</a></li>
                                                <li :class="[paginationbarang.current_page == item ?  'active page-item'  : 'page-item'] " v-for="item =1 in paginationbarang.last_page" :key="item">
                                                    <a class="page-link" v-if="Math.abs(item -1 - paginationbarang.current_page) < 3 || item -1 == paginationbarang.total - 1 " href="#" @click="onClickbarang(item)" :class="{current: paginationbarang.current_page === item-1, last: (item -1== paginationbarang.total - 1 && Math.abs(item -1- paginationbarang.current_page) > 3), first:(item -1== 0 && Math.abs(item -1- paginationbarang.current_page) > 3)}">{{ item+1-1 }}</a>
                                                </li>
                                                <li v-if="paginationbarang.current_page !=paginationbarang.last_page && paginationbarang.current_page <paginationbarang.last_page-5 " :class="[paginationbarang.current_page == paginationbarang.last_page ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarang(paginationbarang.last_page)" style=" cursor: pointer;">{{paginationbarang.last_page}}</a></li>
                                                <!-- <div v-for="item =1 in pagination.last_page" :key="item">
                                                <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] "><a class="page-link" @click="onClick(item)">{{item}}</a></li>
                                            </div> -->
                                                <li class="page-item" :class="[paginationbarang.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClickbarangs(paginationbarang.next_page_url)">Next</a></li>
                                            </ul>
                                        </nav>

                                        <!--//app-pagination-->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="cart.length>0" class="col-md-6">
                        <div class="app-card app-card-orders-table shadow-sm mb-5">
                            <div class="app-card-body">
                                <div class="row g-3 mb-4 ms-2 me-2 align-items-center justify-content-between">
                                    <!-- <div class="col-12">
                                        <h1 class="app-page-title mb-0">Keranjang Barang Keluar</h1>
                                    </div> -->
                                    <div class="col-6">
                                        <label for="">Tujuan*</label>
                                        <input type="text" class="form-control" v-model="tujuan">
                                    </div>
                                    <div class="col-6">
                                        <label for="">Keterangan</label>
                                        <input type="text" class="form-control" v-model="keterangan">
                                    </div>
                                    <div class="col-12">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Nama Produk</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(post, index) in cart" :key="index">
                                                    <th scope="row">{{ index+1 }}</th>
                                                    <td>{{post.barang.nama_barang}}</td>
                                                    <td style="font-size: 18px">
                                                        <input type="number" min="1" v-model="qty[index]" @change="plusQty(index)" class="form-control">
                                                    </td>

                                                    <td>
                                                        <button class="btn btn-danger text-white" @click="hapus(post,index)"><i class='bx bxs-trash-alt'></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="d-flex justify-content-end">

                                        <button class="btn btn-primary text-white mb-2" data-bs-toggle="modal" data-bs-target="#staticCheckout" >Checkout</button>
                                        <!-- Modal -->
                                        <div class="modal fade" id="staticCheckout" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">

                                                    <div class="modal-body">

                                                        <div class="text-center">
                                                            <img :src="'/logo/tanya.png'" alt="Logo" style="width:50px;height:50px">
                                                        </div>
                                                        <br>
                                                        Apakah Anda Yakin Ingin Checkout?
                                                        <br>
                                                        
                                                        <br>

                                                        <div class="text-end">
                                                            <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Tidak</button>
                                                            <button type="button" class="btn btn-primary text-white" @click="checkouts2" data-bs-dismiss="modal">Ya</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Button trigger modal -->
                                        <button type="button" class="btn btn-primary notifberhasil" data-bs-toggle="modal" data-bs-target="#staticSuccess" style="display:none">

                                        </button>

                                        <!-- Modal -->
                                        <div class="modal fade" id="staticSuccess" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">

                                                    <div class="modal-body">
                                                        <div class="text-center">
                                                            <h3> Transaksi Penjualan Berhasil</h3>
                                                            <br>
                                                            <img :src="'/logo/checked.png'" alt="Logo" style="width:50px;height:50px">
                                                            <br>
                                                            <br>
                                                            <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Kembali ke Dashboard</button>
                                                            <button type="button" class="btn btn-primary text-white" @click="checkouts2print" data-bs-dismiss="modal">Print Transaksi</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="app-card app-card-orders-table shadow-sm mb-5">
                            <div class="app-card-body">
                                <div class="row g-3 mb-4 ms-2 me-2 align-items-center justify-content-between">
                                    <div class="col-12">
                                        <h1 class="app-page-title mb-0">Data Barang Keluar</h1>
                                    </div>
                                    <div class="col-auto">
                                        <div class="page-utilities">
                                            <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
                                                <div class="col-auto">
                                                    <div class="docs-search-form row gx-1 align-items-center">

                                                        <div class="col-8">

                                                            <div class="input-group ">
                                                                <input v-model="search" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Search Nama">
                                                                <button @click="getReset" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> X </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 mt-sm-5 mt-md-0">
                                                            <button @click="getSearch" type="submit" class="btn app-btn-secondary">Search</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!--//col-->

                                            </div>
                                            <!--//row-->
                                        </div>
                                        <!--//table-utilities-->
                                    </div>

                                    <div class="col-12">
                                        <div class="table-responsive m-2">
                                            <table class="table app-table-hover mb-0 text-center">
                                                <thead>
                                                    <tr>
                                                        <th class="cell">No</th>
                                                        <th class="cell">Tanggal</th>
                                                        <th class="cell">Kantor Cabang</th>
                                                        <th class="cell">Tujuan</th>
                                                        <th class="cell">Aksi</th>
                                                    </tr>
                                                </thead>

                                                <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                                    <tr v-for="(post, index) in posts" :key="index">
                                                        <td class="cell">{{ index +1 }}</td>
                                                        <td class="cell">{{ post.tanggal  }}</td>
                                                        <td class="cell">{{ post.kantor_cabang.nama_cabang  }}</td>
                                                        <td class="cell">{{ post.tujuan  }}</td>

                                                        <td class="text-center cell">
                                                            <!-- Button trigger modal -->
                                                            <a target="__blank" :href="'../print-brng-keluar?data='+ post.id " class="btn btn-primary text-white btn-sm me-2">PRINT</a>
                                                            <button type="button" class="btn btn-warning text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticEdit'" @click="setEdit(index)">
                                                                Lihat Barang
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody style="border-top: 1px solid grey" v-else>
                                                    <tr>
                                                        <td colspan="9" class="text-center " style="height: 200px; padding-top: 100px;">
                                                            <h3> Loading....</h3>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <nav class="app-pagination mt-3">
                                                <ul class="pagination justify-content-center">
                                                    <li :class="[pagination.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.prev_page_url)">Previous</a></li>
                                                    <div v-for="item =1 in pagination.last_page" :key="item">
                                                        <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] "><a class="page-link" @click="onClick(item)">{{item}}</a></li>
                                                    </div>
                                                    <li class="page-item" :class="[pagination.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.next_page_url)">Next</a></li>
                                                </ul>
                                            </nav>
                                            <!--//app-pagination-->

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    data() {
        return {
            tujuan: '',
            keterangan: '',
            posts: {},
            page: 1,
            pagination: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            posts2: {},
            page2: 1,
            pagination2: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            edit: [],
            edit2: [],
            search2: "",
            cabang2: localStorage.getItem('cabang'),
            userid2: '',
            isActive: true,
            isActive2: false,
            link: this.globalApiUrl,
            loading: false,
            loggedIn: localStorage.getItem('loggedIn'),
            token: localStorage.getItem('token'),
            search: "",
            cabang: '',
            userid: '',
            bayarHutangmessage: [],
            idTransaksi: '',
            bayar: '',
            numberGet: 0,
            invId: '',
            jums: 0,
            jums2: 0,
            totals: 0,
            searchCabang: localStorage.getItem('cabang'),
            cart: [],
            qty: [],
            stok: [],
            searchbarang: '',
            postbarangs: {},
            pagebarang: 1,
            paginationbarang: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },

        }

    },
    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            this.getResults();
            this.getBarangbaru();
            this.feshkeranjang2();
        }
    },
    methods: {
        checkouts2(){
           var form = new FormData();
            form.append('tujuan', this.tujuan);
            form.append('keterangan', this.keterangan);
            form.append('cabang', this.cabang2);
            form.append('qty', JSON.stringify(this.qty));

            axios.post(this.link + 'api/keranjang_barang_keluar_checkout', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Ditambah`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                let routeData = this.$router.resolve({
                    name: 'print.brng.klr.index',
                    query: {
                        data: res.data.data.data
                    }
                });

                window.open(routeData.href, '_blank');
                this.feshkeranjang();
                this.getResults();
                this.getBarangbaru();
            }).catch(error => {
                this.$toast.show(error.response.data.data.message, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
            })
        },
        tambahkeranjang(a) {
            var form = new FormData();
            form.append('barang', a);
            form.append('qty', 1);

            axios.post(this.link + 'api/keranjang_barang_keluar', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Ditambah`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.feshkeranjang(a);
            }).catch(error => {
                this.$toast.show(error.response.data.data.error, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
            })
        },
        feshkeranjang2() {
            axios.get(this.link + 'api/keranjang_barang_keluar', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                var a = 0;
                this.cart = response.data.data

                this.cart.forEach(i => {
                    if (this.qty[a] == null) {
                        this.qty[a] = i.qty;
                        this.stok[a] = i.barang.stok;
                    }

                    a = a + 1;
                });
                console.log(this.qty[a-1]);
            });

        },
        feshkeranjang(b) {
            axios.get(this.link + 'api/keranjang_barang_keluar', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                var a = 0;
                this.cart = response.data.data

                this.cart.forEach(i => {
                    // this.keranjang.filter((x) => {
                    //      x.barang.id.match(b);
                    //     //  console.log(x.barang.id.match(b));
                    // });
                    if (this.qty[a] == null) {
                        this.qty[a] = i.qty;
                        this.stok[a] = i.barang.stok;
                    } else if (i.barang.id == b) {
                        this.qty[a] = i.qty;
                        this.stok[a] = i.barang.stok;
                    } else {

                    }

                    a = a + 1;
                });
                console.log(this.qty);
            });

        },

        onClickbarang(item) {
            this.pagebarang = item;
            this.getBarangbaru();
        },
        getSearchbarang() {
            this.pagebarang = 1;
            this.getBarangbaru();
        },
        onClickbarangs(item) {
            this.loading = true;

            axios.get(item + '&limit=10&name=' + this.searchbarang + '&cabang=' + this.searchCabang)
                .then(response => {
                    this.pagebarang = response.data.data.current_page;
                    this.postbarangs = response.data.data.data;
                    this.paginationbarang = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                });
            this.loading = false;
        },
        getResults() {
            this.loading = true;
            // console.log(this.loading);
            axios.get(this.link + 'api/barang_keluar?limit=10&search=' + this.search + '&cabang=' + this.searchCabang + '&userid=' + this.userid + '&page=' + this.page, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    // console.log(response.data.data.transaksi.data)
                    this.jums = response.data.data.jum;
                    this.jums2 = response.data.data.jum2;
                    this.totals = response.data.data.total;
                    this.posts = response.data.data.data;
                    this.pagination = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                }).then(
                    this.loading = false
                );
            // console.log(this.loading);
        },
        getBarangbaru() {
            axios.get(this.link + 'api/barang?limit=5&name=' + this.searchbarang + '&page=' + this.pagebarang + '&cabang=' + this.cabang2)
                .then(response => {
                    console.log(response.data.data.data)
                    this.postbarangs = response.data.data.data;
                    this.paginationbarang = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                }).then(
                    this.loading = false
                );
        },
        getbayar(a) {
            var harga_fix = 0;
            this.posts[a].log_hutang.forEach(i => {

                harga_fix = harga_fix + i.bayar
            });
            console.log(harga_fix);
            return harga_fix;
        },

        kliklink() {
            this.isActive = true;
            this.isActive2 = false;
        },
        kliklink2() {
            this.isActive = false;
            this.isActive2 = true;
        },
        onClick(a) {
            this.page = a;
            this.getResults();
        },
        onClick2(a) {
            this.page2 = a;
            this.getResults2();
        },
        onClicks(a) {
            console.log(this.loading);
            axios.get(a + '&limit=10&search=' + this.search + '&cabang=' + this.cabang + '&userid=' + this.userid, {
                    headers: {
                        'Authorization': this.token
                    }
                })
                .then(response => {
                    this.posts = response.data.data.transaksi.data;
                    this.jums = response.data.data.jum;
                    this.jums2 = response.data.data.jum2;
                    this.totals = response.data.data.total;
                    console.log(this.jums);
                    this.pagination = {
                        "total": response.data.data.transaksi.total,
                        "per_page": response.data.data.transaksi.per_page,
                        "current_page": response.data.data.transaksi.current_page,
                        "last_page": response.data.data.transaksi.last_page,
                        "next_page_url": response.data.data.transaksi.next_page_url,
                        "prev_page_url": response.data.data.transaksi.prev_page_url,
                        "from": response.data.data.transaksi.from,
                        "to": response.data.data.transaksi.to,
                    }
                }).then(
                    // this.loading = false
                );
        },
        plusQty(index){
            console.log(this.qty);
            if(this.qty[index]==0){
                this.qty[index] = 1;
            }else{
                if (this.qty[index] < this.stok[index]) {
                    this.qty[index] = this.qty[index];
                }else{
                    this.qty[index] = this.stok[index];
                }
            }
        },
        hapus(a,b){
            var form = new FormData();
            form.append('cart', a.id);

            axios.post(this.link + 'api/keranjang_barang_keluar_delete', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Dihapus`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // this.quantity.splice(b, 1);
                this.qty.splice(b, 1);
                this.cart.splice(b, 1);
                this.feshkeranjang(a.barang.id);
            }).catch(error => {
                this.$toast.show(error.response.data.data.message, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
            })
        },
        setTransaksi(a) {
            this.idTransaksi = this.posts[a].id;
            var harga_fix = 0;
            this.posts[a].log_hutang.forEach(i => {

                harga_fix = harga_fix + i.bayar
            });
            var harga_fix2 = 0;

            this.posts[a].detail_transaksi.forEach(i => {

                harga_fix2 = harga_fix2 + (i.harga_fix * i.qty)
            });

            this.numberGet = Math.abs(harga_fix - harga_fix2);
            console.log(this.idTransaksi);
        },
        simpanHutang() {
            this.bayarHutangmessage = [];
            var form = new FormData();
            form.append('bayar', this.bayar);
            form.append('transaksiid', this.idTransaksi);
            // form.append('supplierid', this.supplierid);

            axios.post(this.link + 'api/transaksi-hutang-bayar', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Ditambah`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambahError = true;
                this.$toast.show(error.response.data.data.error, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // if()
                this.bayarHutangmessage = error.response.data.data.error;
                console.log(this.tambahMessage);
            })
        },
        setEdit(a) {
            this.edit = this.posts[a].detail_notapemindahan;
        },
        setEdit2(a) {
            this.invId = this.posts[a].invoice;
            this.edit2 = this.posts[a].log_hutang;
        },
        total(a) {
            var harga_fix = 0;

            this.posts[a].detail_transaksi.forEach(i => {

                harga_fix = harga_fix + (i.harga_fix * i.qty)
            });
            return harga_fix;
        },
        total2(a) {
            var harga_fix = 0;

            this.posts2[a].detail_transaksi.forEach(i => {

                harga_fix = harga_fix + (i.harga_fix * i.qty)
            });
            return harga_fix;
        },

        filterdate(a) {
            // console.log(a);
            return moment(String(a)).format('DD/MM/YYYY')
        },
        prints(a, b) {

            let routeData = this.$router.resolve({
                name: 'hutang.print',
                query: {
                    data: [a, b]

                }
            });
            window.open(routeData.href, '_blank');
        },
        getSearch() {
            this.getResults();
        },
        getReset() {
            this.search = '';
            this.getResults();
        },

    }

}
</script>
